export const routes = [
    {
        path: '/group_schedules',
        name: 'group_schedules.browse',
        component: () => import(/* webpackChunkName: "GroupSchedulesBrowse" */ '@/views/app/GroupSchedulesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/group_schedules/create',
        name: 'group_schedules.create',
        component: () => import(/* webpackChunkName: "GroupSchedulesActions" */ '@/views/app/GroupSchedulesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/group_schedules/:id/edit',
        name: 'group_schedules.edit',
        component: () => import(/* webpackChunkName: "GroupSchedulesActions" */ '@/views/app/GroupSchedulesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/group_schedules/:id/delete',
        name: 'group_schedules.delete',
        component: () => import(/* webpackChunkName: "GroupSchedulesActions" */ '@/views/app/GroupSchedulesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]